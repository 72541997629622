"use strict"
var __decorate =
    (this && this.__decorate) ||
    function(decorators, target, key, desc) {
        var c = arguments.length,
            r = c < 3 ? target : desc === null ? (desc = Object.getOwnPropertyDescriptor(target, key)) : desc,
            d
        if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc)
        else for (var i = decorators.length - 1; i >= 0; i--) if ((d = decorators[i])) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r
        return c > 3 && r && Object.defineProperty(target, key, r), r
    }
var __awaiter =
    (this && this.__awaiter) ||
    function(thisArg, _arguments, P, generator) {
        function adopt(value) {
            return value instanceof P
                ? value
                : new P(function(resolve) {
                      resolve(value)
                  })
        }
        return new (P || (P = Promise))(function(resolve, reject) {
            function fulfilled(value) {
                try {
                    step(generator.next(value))
                } catch (e) {
                    reject(e)
                }
            }
            function rejected(value) {
                try {
                    step(generator["throw"](value))
                } catch (e) {
                    reject(e)
                }
            }
            function step(result) {
                result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected)
            }
            step((generator = generator.apply(thisArg, _arguments || [])).next())
        })
    }
var __generator =
    (this && this.__generator) ||
    function(thisArg, body) {
        var _ = {
                label: 0,
                sent: function() {
                    if (t[0] & 1) throw t[1]
                    return t[1]
                },
                trys: [],
                ops: [],
            },
            f,
            y,
            t,
            g
        return (
            (g = { next: verb(0), throw: verb(1), return: verb(2) }),
            typeof Symbol === "function" &&
                (g[Symbol.iterator] = function() {
                    return this
                }),
            g
        )
        function verb(n) {
            return function(v) {
                return step([n, v])
            }
        }
        function step(op) {
            if (f) throw new TypeError("Generator is already executing.")
            while (_)
                try {
                    if (((f = 1), y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)) return t
                    if (((y = 0), t)) op = [op[0] & 2, t.value]
                    switch (op[0]) {
                        case 0:
                        case 1:
                            t = op
                            break
                        case 4:
                            _.label++
                            return { value: op[1], done: false }
                        case 5:
                            _.label++
                            y = op[1]
                            op = [0]
                            continue
                        case 7:
                            op = _.ops.pop()
                            _.trys.pop()
                            continue
                        default:
                            if (!((t = _.trys), (t = t.length > 0 && t[t.length - 1])) && (op[0] === 6 || op[0] === 2)) {
                                _ = 0
                                continue
                            }
                            if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                                _.label = op[1]
                                break
                            }
                            if (op[0] === 6 && _.label < t[1]) {
                                _.label = t[1]
                                t = op
                                break
                            }
                            if (t && _.label < t[2]) {
                                _.label = t[2]
                                _.ops.push(op)
                                break
                            }
                            if (t[2]) _.ops.pop()
                            _.trys.pop()
                            continue
                    }
                    op = body.call(thisArg, _)
                } catch (e) {
                    op = [6, e]
                    y = 0
                } finally {
                    f = t = 0
                }
            if (op[0] & 5) throw op[1]
            return { value: op[0] ? op[1] : void 0, done: true }
        }
    }
exports.__esModule = true
var status_1 = require("./status")
function staticImplements() {
    return function(constructor) {
        constructor
    }
}
var Utils = /** @class */ (function() {
    function Utils() {}
    Utils_1 = Utils
    Utils.versionToNumber = function(version) {
        var c = version.split(".")
        var num_place = ["", "0", "00", "000", "0000"],
            r = num_place.reverse()
        for (var i = 0; i < c.length; i++) {
            var len = c[i].length
            c[i] = r[len] + c[i]
        }
        return parseInt(c.join(""))
    }
    Utils.paddingZero = function(num) {
        return (num >= 10 ? "" : "0") + num
    }
    Utils.wait = function(cb, ms) {
        setTimeout(function() {
            return cb()
        }, ms)
    }
    /**
     * 拷贝文本到剪切板
     * @param text 文本
     */
    Utils.copyText = function(text) {
        var e = document.createElement("input")
        // 添加组件到文档流
        document.body.appendChild(e)
        e.value = text
        e.style.position = "absolute"
        e.style.opacity = "0"
        // 选中
        e.select()
        // 执行拷贝命令
        document.execCommand("copy")
        // 移除组件
        document.body.removeChild(e)
    }
    /**
     * json 转换 formdata
     * @param json json
     */
    Utils.jsonToFormData = function(json) {
        if (json instanceof FormData) {
            return json
        }
        var formData = new FormData()
        this.each(Object.keys(json), function(v) {
            formData.append(v, json[v])
        })
        return formData
    }
    Utils.getUuid = function() {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
        }
        return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4()
    }
    Utils.getStringId = function(len) {
        if (len === void 0) {
            len = 8
        }
        var result = ""
        for (var i = 0; i < len; i++) {
            var ranNum = Math.ceil(Math.random() * 25)
            result += String.fromCharCode(65 + ranNum)
        }
        return result
    }
    Utils.getNode = function(evt) {
        return evt.srcElement || evt.target
    }
    /**
     * 获取 HTML Element
     * @param s 选择器
     */
    Utils.getElement = function(s, cb, e) {
        var doc
        if (e && e instanceof Element) {
            doc = e
        } else {
            doc = document
        }
        var dom = doc.querySelector(s)
        cb && dom && cb(dom)
        return dom
    }
    /**
     * 获取 HTML Element 数组
     * @param s 选择器
     */
    Utils.getElements = function(s, cb, e) {
        var doc
        if (e && e instanceof Element) {
            doc = e
        } else {
            doc = document
        }
        var r = []
        // 循环获取组件
        Utils_1.each(s, function(v) {
            var _e = doc.querySelector(v)
            _e && r.push(_e)
        })
        cb && r.length === s.length && cb.apply(cb, r)
        return r
    }
    /**
     * 获取所有 HTML Element
     * @param selectors 选择器
     */
    Utils.getElementAll = function(selectors, cb, e) {
        var doms
        if (e && e instanceof Element) {
            doms = e.querySelectorAll(selectors)
        } else {
            doms = document.querySelectorAll(selectors)
        }
        cb && doms && Utils_1.eachNode(doms, cb)
        return doms
    }
    /**
     * 获取事件中的目标组件
     * @param evt 事件
     */
    Utils.getTarget = function(evt) {
        var element = evt.target || evt.srcElement
        return element
    }
    /**
     * 设置表单组件数据
     * @param form 表单
     * @param data 数据
     */
    Utils.setFormData = function(form, data) {
        var fe
        if ("string" === typeof form) {
            fe = Utils_1.getElement(form)
        } else {
            fe = form
        }
        var es = fe.elements
        for (var i = 0; i < es.length; i++) {
            var e = es[i]
            var n = e.name
            if (n === "") {
                continue
            }
            switch (e.type) {
                case "checkbox":
                    data[n] && e.setAttribute("checked", "")
                    break
                case "select-one":
                    var o = e.querySelector('option[value="' + data[n] + '"]')
                    o && o.setAttribute("selected", "")
                    break
                default:
                    e.value = data[n]
            }
        }
        return fe
    }
    /**
     * 获取表单组件数据
     * @param form 表单
     */
    Utils.getFormData = function(form) {
        var _this = this
        return new Promise(function(resolve) {
            var fe
            if ("string" === typeof form) {
                fe = Utils_1.getElement(form)
            } else {
                fe = form
            }
            var es = fe.querySelectorAll("[name]")
            var data = {}
            var getData = function(resolve) {
                return __awaiter(_this, void 0, void 0, function() {
                    var i, e, val, cb_value, str, msg, verify
                    return __generator(this, function(_a) {
                        switch (_a.label) {
                            case 0:
                                i = 0
                                _a.label = 1
                            case 1:
                                if (!(i < es.length)) return [3 /*break*/, 7]
                                e = es[i]
                                if (!e.name) {
                                    return [3 /*break*/, 6]
                                }
                                if (!e.getValue) return [3 /*break*/, 4]
                                cb_value = e.getValue()
                                if (!(cb_value instanceof Promise)) return [3 /*break*/, 3]
                                return [4 /*yield*/, cb_value]
                            case 2:
                                cb_value = _a.sent()
                                _a.label = 3
                            case 3:
                                if (cb_value instanceof status_1["default"]) {
                                    if (cb_value.isBlank()) {
                                        return [2 /*return*/, resolve(cb_value)]
                                    }
                                    val = cb_value.getData()
                                } else {
                                    val = cb_value
                                }
                                return [3 /*break*/, 5]
                            case 4:
                                val = e.value
                                _a.label = 5
                            case 5:
                                if (val && e.type === "number") {
                                    val = Number(val)
                                }
                                // 是否为必选项
                                if (e.hasAttribute("required") && e.getAttribute("required") !== "false" && this.isBlank(val)) {
                                    str = e.getAttribute("message")
                                    msg = this.isExist(str) ? str : (e.title || e.name) + " 不能为空"
                                    return [2 /*return*/, resolve(new status_1["default"](400).setMessage(msg).setData(e))]
                                }
                                if (e.hasAttribute("verify") && val) {
                                    verify = e.getAttribute("verify")
                                    // 判断是否为正则表达式
                                    if (/^\/+[\\/g]$/.test(verify) ? new RegExp(verify).test(val) : this.verify(verify, val)) {
                                        return [2 /*return*/, resolve(new status_1["default"](400).setMessage(e.getAttribute("verify-message") || (e.title || e.name) + " 格式不正确"))]
                                    }
                                }
                                switch (e.type) {
                                    case "checkbox":
                                        data[e.name] = e.checked
                                        break
                                    default:
                                        this.setStringObject(data, e.name, val)
                                }
                                _a.label = 6
                            case 6:
                                i++
                                return [3 /*break*/, 1]
                            case 7:
                                resolve(new status_1["default"](200).setData(data))
                                return [2 /*return*/]
                        }
                    })
                })
            }
            getData(resolve)
        })
    }
    Utils.verify = function(type, value) {
        var verify = null
        switch (type) {
            case "english":
                verify = /^[^\u4e00-\u9fa5]+$/g
                break
            case "phone":
                verify = /^1\d{10}$/
                break
            case "email":
                verify = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/
                break
            case "idNum":
                verify = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
                break
            case "password":
                verify = /^(?![^a-zA-Z]+$)(?!\D+$).{8,15}$/
        }
        if (verify) {
            return new RegExp(verify).test(value)
        }
        return true
    }
    Utils.verifyIdNum = function(value) {
        // 15位和18位身份证号码的正则表达式
        var regIdCard = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)/
        var idCard = value
        // 如果通过该验证，说明身份证格式正确，但准确性还需计算
        if (regIdCard.test(idCard)) {
            if (idCard.length == 18) {
                var idCardWi = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2) // 将前17位加权因子保存在数组里
                var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2) // 这是除以11后，可能产生的11位余数、验证码，也保存成数组
                var idCardWiSum = 0 // 用来保存前17位各自乘以加权因子后的总和
                for (var i = 0; i < 17; i++) {
                    idCardWiSum += idCard.substring(i, i + 1) * idCardWi[i]
                }

                var idCardMod = idCardWiSum % 11 // 计算出校验码所在数组的位置
                var idCardLast = idCard.substring(17) // 得到最后一位身份证号码

                // 如果等于2，则说明校验码是10，身份证号码最后一位应该是X
                if (idCardMod == 2) {
                    return idCardLast == "X" || idCardLast == "x"
                } else {
                    // 用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
                    return idCardLast == idCardY[idCardMod]
                }
            } else {
                return true
            }
        } else {
            return false
        }
    }
    /**
     * 写入对象值
     * @param o 对象
     * @param str 字符串 key
     * @param val 值
     */
    Utils.setStringObject = function(o, str, val) {
        var _a
        if (str.indexOf(".") <= -1) {
            o[str] = val
        } else {
            var strs = str.split(".")
            for (var i = 0, l = strs.length; i < l; i++) {
                var s = strs[i]
                if (i === l - 1) {
                    if (/.*\[\d+\]$/.test(strs[i - 1])) {
                        var idx = strs[i - 1].replace(/.*\[(\d+)\]$/, "$1")
                        o[idx] ? (o[idx][s] = val) : (o[idx] = ((_a = {}), (_a[s] = val), _a))
                    } else {
                        o[s] = val
                    }
                } else {
                    if (/.*\[\d+\]$/.test(s)) {
                        s = s.replace(/\[\d+\]$/, "")
                        if (!o[s]) {
                            o[s] = []
                        }
                        o = o[s]
                    } else {
                        if (!o[s]) {
                            o[s] = {}
                        }
                        o = o[s]
                    }
                }
            }
        }
    }
    Utils.getStringObject = function(o, str) {
        if (str.indexOf(".") <= -1) {
            if (/.*\[\d+\]$/.test(str)) {
                str.replace(/(.*)\[(\d+)\]$/, "$1")
                return o[RegExp.$1][RegExp.$2]
            } else {
                return o[str]
            }
        } else {
            var strs = str.split(".")
            for (var i = 0, l = strs.length; i < l; i++) {
                var s = strs[i]
                if (i === l - 1) {
                    if (/.*\[\d+\]$/.test(strs[i - 1])) {
                        var idx = strs[i - 1].replace(/.*\[(\d+)\]$/, "$1")
                        return o[idx]
                    } else {
                        return o[s]
                    }
                } else {
                    if (/.*\[\d+\]$/.test(s)) {
                        s = s.replace(/\[\d+\]$/, "")
                        if (!o[s]) {
                            return null
                        }
                        o = o[s]
                    } else {
                        if (!o[s]) {
                            return null
                        }
                        o = o[s]
                    }
                }
            }
        }
    }
    /**
     * 判断值是否为 JSON
     */
    Utils.isJson = function(value) {
        if (typeof value === "object") {
            return true
        }
        if (typeof value === "string") {
            return /^(\[|{).*(\]|})$/.test(value)
        }
        return false
    }
    /**
     * 临时组件
     * @param n 组件名称
     * @param cb 回调函数
     */
    Utils.shortElement = function(n, cb) {
        var e = document.createElement(n)
        // 临时组件应当隐藏
        e.style.display = "none"
        // 添加至 body
        this.appendChild(e)
        // 处理事务
        cb && cb(e)
        // 删除组件
        this.removeChild(e)
    }
    /**
     * 仿 forEach 循环
     * @param a 数组
     * @param cb 回调函数
     * @param condition 条件
     */
    Utils.each = function(a, cb, condition) {
        if (a == null || !(a instanceof Array)) {
            return []
        }

        for (var i = 0, len = a.length; i < len; i++) {
            if (condition ? !condition(a[i]) : false) {
                continue
            }
            var _cb = cb(a[i], i, i === len - 1)
            if (_cb === "delete") {
                a.splice(i, 1)
                i-- // 下标自减
                len-- // 总长度自减
            } else if (_cb === "break") {
                break
            } else if (_cb === "move-after") {
                i < len - 1 && (a[i] = a.splice(i + 1, 1, a[i])[0])
                break
            } else if (_cb === "move-before") {
                i > 0 && (a[i] = a.splice(i - 1, 1, a[i])[0])
                break
            } else if (_cb) {
                a[i] = _cb
            }
        }
        return a
    }
    /**
     * 对象 遍历
     * @param obj 对象
     * @param cb 回调函数
     * @param condition 条件
     */
    Utils.eachObj = function(obj, cb, condition) {
        for (var i = 0, keys = Object.keys(obj), len = keys.length; i < len; i++) {
            var key = keys[i]
            if (condition ? !condition(obj[key]) : false) {
                continue
            }
            var _cb = cb(key, obj[key], i, i === len - 1)
            if (_cb === "delete") {
                delete obj[key]
                i--
            } else if (_cb === "break") {
                break
            } else if (_cb) {
                obj[key] = _cb
            }
        }
        return obj
    }
    Utils.eachNode = function(a, cb) {
        for (var i = 0, l = a.length; i < l; i++) {
            var _cb = cb(a[i], i, i === l - 1)
            if (_cb === "break") {
                break
            }
        }
        return a
    }
    /**
     * 查找数组指定元素
     * @param d 数组
     * @param cb 回调函数
     */
    Utils.find = function(d, condition, cb) {
        for (var i = 0, l = d.length; i < l; i++) {
            var _cb = condition(d[i])
            if (_cb) {
                var res = { i: i, data: d[i] }
                // 回调处理事件
                cb && cb(res)
                // 返回结果
                return res
            }
        }
        return { i: -1, data: null }
    }
    /**
     * 查找数组所有指定元素
     * @param d 数组
     * @param cb 回调函数
     */
    Utils.findAll = function(d, cb) {
        var res = []
        for (var i = 0, l = d.length; i < l; i++) {
            var _cb = cb(d[i])
            if (_cb) {
                res.push(d[i])
            }
        }
        return res
    }
    /**
     * 检验是否为空
     */
    Utils.verifyIsEmpty = function(d, j) {
        var msg = null
        this.each(Object.keys(j), function(v) {
            var item = d[v]
            if (!item) {
                msg = j[v]
                return "break"
            } else if (item instanceof Array && item.length <= 0) {
                msg = j[v]
                return "break"
            } else if (item instanceof Object && Object.keys(item).length <= 0) {
                msg = j[v]
                return "break"
            }
        })
        return msg
    }
    Utils.untie = function(fun) {
        fun.match(/^(\w+)\((.*)\)$/)
        var name = RegExp.$1,
            param = RegExp.$2
        return { name: name, param: param ? param.split(",") : null }
    }
    Utils.getFunByStr = function(obj, str) {
        var _this = this
        var fun = null,
            params = null
        this.each(str.split("."), function(v, i, e) {
            if (e) {
                var _a = _this.untie(v),
                    name_1 = _a.name,
                    param = _a.param
                fun = fun ? fun[name_1] : obj[name_1]
                params = param
            } else if (!fun && obj[v]) {
                fun = obj[v]
            } else if (fun && fun[v]) {
                fun = fun[v]
            } else {
                return "break"
            }
        })
        return { fun: fun, params: params }
    }
    Utils.appendChild = function(element, place) {
        if (place === void 0) {
            place = "body"
        }
        if (document[place]) {
            document[place].appendChild(element)
        } else {
            document.getElementsByTagName(place)[0].appendChild(element)
        }
    }
    Utils.removeChild = function(el) {
        // 检索组件
        var e = "string" === typeof el ? Utils_1.getElement(el) : el
        // 存在则移除
        e && document.body.removeChild(e)
    }
    Utils.removeChildAll = function(el) {
        // 检索全部组件
        var e = "string" === typeof el ? Utils_1.getElementAll(el) : el
        // 存在则循环移除
        if (e) {
            for (var i = 0; i < e.length; i++) {
                document.body.removeChild(e[i])
            }
        }
    }
    /**
     * 拷贝对象
     * @param obj 对象
     */
    Utils.copy = function(obj) {
        return JSON.parse(JSON.stringify(obj))
    }
    /**
     * 对象转 URL 参数
     * @param obj 对象
     * @param url 请求链接（可选，用于判断链接是否带参数）
     */
    Utils.jsonToParams = function(json, url) {
        var s = ""
        var p = url ? /\?/.test(url) : false
        this.each(Object.keys(json), function(n, i) {
            var v = json[n]
            if ("undefined" !== typeof v) {
                s += "" + (s.length === 0 && !p ? "?" : "&") + n + "=" + v
            }
        })
        return url ? url + s : s
    }
    Utils.paramsToJson = function(url) {
        // 没有参数，返回空对象
        if (!/\?/.test(url)) {
            return {}
        }
        var parames = {}
        url = decodeURI(url)
        var vars = url.substr(url.indexOf("?") + 1).split("&")
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=")
            parames[pair[0]] = pair[1]
        }
        return parames
    }
    /**
     * 延时
     * @param ms 毫秒
     */
    Utils.delay = function(ms) {
        return new Promise(function(resolve) {
            setTimeout(resolve, ms)
        })
    }
    /**
     * 设置属性
     * @param e 组件
     * @param attribute 属性
     */
    Utils.setAttribute = function(e, attribute) {
        var keys = Object.keys(attribute)
        for (var i = 0; i < keys.length; i++) {
            var item = keys[i]
            e.setAttribute(item, attribute[item])
        }
    }
    /**
     * 创建组件
     * @param tagName 标签名称
     * @param attribute 属性
     */
    Utils.createElement = function(tagName, a) {
        var e = document.createElement(tagName)
        for (var i = 0, keys = Object.keys(a); i < keys.length; i++) {
            var key = keys[i]
            switch (key) {
                case "id":
                    a.id && (e.id = a.id)
                    break
                case "class":
                    typeof a["class"] === "string"
                        ? e.classList.add(a["class"])
                        : a["class"] &&
                          Utils_1.each(a["class"], function(v) {
                              e.classList.add(v)
                          })
                    break
                case "attribute":
                    a.attribute && this.setAttribute(e, a.attribute)
                    break
                case "style":
                    Object.assign(e.style, a.style)
                    break
                case "event":
                    Object.assign(e, a.event)
                    break
                default:
                    e[key] = a[key]
            }
        }
        return e
    }
    Utils.isPlainObject = function(obj) {
        var proto, Ctor
        // Detect obvious negatives
        // Use toString instead of jQuery.type to catch host objects
        if (!obj || Object.toString.call(obj) !== "[object Object]") {
            return false
        }
        proto = Object.getPrototypeOf(obj)
        // Objects with no prototype (e.g., `Object.create( null )`) are plain
        if (!proto) {
            return true
        }
        var hasOwnProperty = Object.hasOwnProperty
        var toString = hasOwnProperty.toString
        // Objects with prototype are plain iff they were constructed by a global Object function
        Ctor = hasOwnProperty.call(proto, "constructor") && proto.constructor
        return typeof Ctor === "function" && toString.call(Ctor) === toString.call(Object)
    }
    Utils.isFunction = function(obj) {
        return typeof obj === "function" && typeof obj.nodeType !== "number"
    }
    Utils.extend = function(target) {
        var targets = []
        for (var _i = 1; _i < arguments.length; _i++) {
            targets[_i - 1] = arguments[_i]
        }
        var options,
            name,
            src,
            copy,
            copyIsArray,
            clone,
            i = 1,
            length = arguments.length,
            deep = false
        // Handle a deep copy situation
        if (typeof target === "boolean") {
            deep = target
            // Skip the boolean and the target
            target = arguments[i] || {}
            i++
        }
        // Handle case when target is a string or something (possible in deep copy)
        if (typeof target !== "object" && !this.isFunction(target)) {
            target = {}
        }
        // Extend jQuery itself if only one argument is passed
        if (i === length) {
            target = this
            i--
        }
        for (; i < length; i++) {
            // Only deal with non-null/undefined values
            if ((options = arguments[i]) != null) {
                // Extend the base object
                for (name in options) {
                    copy = options[name]
                    // Prevent Object.prototype pollution
                    // Prevent never-ending loop
                    if (name === "__proto__" || target === copy) {
                        continue
                    }
                    // Recurse if we're merging plain objects or arrays
                    if (deep && copy && (this.isPlainObject(copy) || (copyIsArray = Array.isArray(copy)))) {
                        src = target[name]
                        // Ensure proper type for the source value
                        if (copyIsArray && !Array.isArray(src)) {
                            clone = []
                        } else if (!copyIsArray && !this.isPlainObject(src)) {
                            clone = {}
                        } else {
                            clone = src
                        }
                        copyIsArray = false
                        // Never move original objects, clone them
                        target[name] = this.extend(deep, clone, copy)
                        // Don't bring in undefined values
                    } else if (copy !== undefined) {
                        target[name] = copy
                    }
                }
            }
        }
        // Return the modified object
        return target
    }
    Utils.extendPrototype = function(target) {
        var p = Object.getPrototypeOf(target)
        this.each(Object.getOwnPropertyNames(p), function(k) {
            if ("constructor" !== k) {
                target[k] = p[k]
            }
        })
        return target
    }
    Utils.HTMLEncode = function(html) {
        return this.createEscaper(this.escapeMap)(html)
    }
    Utils.HTMLDecode = function(text) {
        return this.createEscaper(this.invert(this.escapeMap))(text)
    }
    Utils.invert = function(obj) {
        var result = {}
        var keys = Object.keys(obj)
        for (var i = 0, length = keys.length; i < length; i++) {
            result[obj[keys[i]]] = keys[i]
        }
        return result
    }
    Utils.createEscaper = function(map) {
        // Functions for escaping and unescaping strings to/from HTML interpolation.
        var escaper = function(match) {
            return map[match]
        }
        // Regexes for identifying a key that needs to be escaped.
        var source = "(?:" + Object.keys(map).join("|") + ")"
        var testRegexp = RegExp(source)
        var replaceRegexp = RegExp(source, "g")
        return function(string) {
            string = string == null ? "" : "" + string
            return testRegexp.test(string) ? string.replace(replaceRegexp, escaper) : string
        }
    }
    /**
     * 判断当前环境是否为移动端
     */
    Utils.isMobile = function() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
    /**
     * 判断是否为空
     * @param target 目标值
     */
    Utils.isBlank = function(target) {
        if ("undefined" === typeof target || target === null) {
            return true
        }
        if (("number" === typeof target && !isNaN(target)) || "boolean" === typeof target) {
            return false
        }
        // 是否为字符串值，且全部为空格
        if ("string" === typeof target && /^[ ]+$/.test(target)) {
            return true
        }
        // 数组类型判断内容是否为空
        if (target instanceof Array && target.length <= 0) {
            return true
        }
        return !target
    }
    /**
     * 判断是否存在
     * @param target 目标值
     */
    Utils.isExist = function(target) {
        return !this.isBlank(target)
    }
    Utils.search = function(obj, name) {
        if ("object" !== typeof obj) {
            return "obj must be an object"
        }
        function s_j(json) {
            for (var n in json) {
                if (n === name) {
                    return n
                }
                var v = json[n]
                if (v instanceof Array) {
                    return s_a(v)
                } else if (v instanceof Object) {
                    return s_j(v)
                }
            }
            return null
        }
        function s_a(arr) {
            for (var i = 0; i < arr.length; i++) {
                var v = arr[i]
                if (v instanceof Array) {
                    return s_a(v)
                } else if (v instanceof Object) {
                    return s_j(v)
                }
            }
            return null
        }
        if (obj instanceof Array) {
            return s_a(obj)
        } else {
            return s_j(obj)
        }
    }
    var Utils_1
    Utils.escapeMap = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#x27;",
        "`": "&#x60;",
    }
    Utils = Utils_1 = __decorate([staticImplements()], Utils)
    return Utils
})()
exports["default"] = Utils
// 加载文件类
var LoadFile = /** @class */ (function() {
    function LoadFile() {}
    /**
     * 判断文件是否存在
     * @param url 路径
     */
    LoadFile.isExist = function(url) {
        // 不支持检测将跳过检测
        if (!XMLHttpRequest) {
            console.log("当前浏览器不支持XMLHttpRequest")
            return true
        }
        var xmlhttp = new XMLHttpRequest()
        xmlhttp.open("GET", url, false)
        try {
            xmlhttp.send()
            return xmlhttp.readyState == 4 && xmlhttp.status == 0
        } catch (e) {
            return false
        }
    }
    LoadFile.getResponseTextBySync = function(url) {
        var xmlhttp = new XMLHttpRequest()
        xmlhttp.open("GET", url, false)
        try {
            xmlhttp.send()
            return new status_1["default"](xmlhttp.status).setMessage(xmlhttp.statusText).setData(xmlhttp.responseText)
        } catch (e) {
            return status_1["default"].of(500)
        }
    }
    LoadFile.getResponseText = function(url) {
        return new Promise(function(resolve) {
            var xmlhttp = new XMLHttpRequest()
            xmlhttp.open("GET", url, true)
            try {
                xmlhttp.send()
                xmlhttp.onreadystatechange = function() {
                    if (xmlhttp.readyState === 4) {
                        resolve(new status_1["default"](xmlhttp.status).setMessage(xmlhttp.statusText).setData(xmlhttp.responseText))
                    }
                }
            } catch (e) {
                resolve(new status_1["default"](500))
            }
        })
    }
    /**
     * 加载 script 文件
     * @param src 路径
     * @param cb 加载完成回调函数
     */
    // public static script(src: string | string[]): Promise<void> {
    //     return new Promise(async (resolve) => {
    //         Utils.removeChildAll("script[page-js]")
    //         const load = (s: string) => {
    //             return new Promise(resolve => {
    //                 var script = document.createElement("script")
    //                 script.type = "text/javascript"
    //                 script.src = s
    //                 script.setAttribute("page-js", s)
    //                 script.onload = script.onerror = resolve
    //                 Utils.appendChild(script)
    //             })
    //         }
    //         for (let i = 0, a = typeof src === 'string' ? [src] : src; i < a.length; i++) {
    //             await load(a[i])
    //         }
    //         resolve()
    //     })
    // }
    /**
     * 加载 script 文本文件
     * @param text 文本
     */
    // public static scriptText(text: string | string[]): Promise<void> {
    //     return new Promise(async resolve => {
    //         const load = (t: string) => {
    //             return new Promise(resolve => {
    //                 var script = document.createElement('script')
    //                 script.type = 'text/javascript'
    //                 script.innerHTML = t
    //                 script.setAttribute('page-js', 'internal')
    //                 script.onload = resolve
    //                 Utils.appendChild(script)
    //             })
    //         }
    //         for (let i = 0, a = typeof text === 'string' ? [text] : text; i < a.length; i++) {
    //             await load(a[i])
    //         }
    //         resolve()
    //     })
    // }
    /**
     * 加载 style 文件
     * @param src 路径
     * @param cb 加载完成回调函数
     */
    // public static style(src: string | string[]): Promise<void> {
    //     return new Promise(async resolve => {
    //         Utils.removeChildAll("script[page-css]")
    //         const load = (s: string) => {
    //             return new Promise(resolve => {
    //                 var link = document.createElement('link')
    //                 link.rel = 'stylesheet'
    //                 link.href = s
    //                 link.setAttribute("page-css", s)
    //                 link.onload = link.onerror = resolve
    //                 Utils.appendChild(link, 'head')
    //             })
    //         }
    //         for (let i = 0, a = typeof src === 'string' ? [src] : src; i < a.length; i++) {
    //             await load(a[i])
    //         }
    //         resolve()
    //     })
    // }
    LoadFile.html = function(src) {
        return this.getResponseText(src)
    }
    return LoadFile
})()
