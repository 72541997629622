"use strict";
exports.__esModule = true;
var Status = /** @class */ (function () {
    function Status(status) {
        this.status = 200;
        this.message = "success";
        this.data = null;
        if (Status.equal(status)) {
            this.data = status.data;
            this.status = status.status;
            this.message = status.message;
            this.code = status.code;
            this.info = status.info;
        }
        else {
            this.status = status;
        }
    }
    Status.prototype.setMessage = function (msg) {
        this.message = msg;
        return this;
    };
    Status.prototype.setData = function (data) {
        this.data = data;
        return this;
    };
    Status.prototype.setCode = function (code) {
        this.code = code;
        return this;
    };
    Status.prototype.setInfo = function (info) {
        this.info = info;
        return this;
    };
    Status.prototype.getStatus = function () {
        return this.status;
    };
    Status.prototype.getMessage = function () {
        return this.message;
    };
    Status.prototype.getData = function () {
        return this.data;
    };
    Status.prototype.getCode = function () {
        return this.code;
    };
    Status.prototype.getInfo = function () {
        return this.info;
    };
    Status.prototype.isNormal = function () {
        return this.status === 200;
    };
    Status.prototype.isBlank = function () {
        return this.status !== 200;
    };
    Status.of = function (status) {
        return new Status(status);
    };
    /**
     * 判断是否为 Status 对象
     * @param status 目标变量
     */
    Status.equal = function (target) {
        if ("object" === typeof target && Object.prototype.hasOwnProperty.call(target, "status") && Object.prototype.hasOwnProperty.call(target, "message")) {
            return true;
        }
        return false;
    };
    return Status;
}());
exports["default"] = Status;
